import React, { useState } from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import * as styles from "../index.module.scss"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { PatientEmergencyContact } from "../../../sharedTypes"
import { useIsMutating, useMutation } from "@tanstack/react-query"
import { updatePatientEmergencyContact } from "./api"
import { CanopyTextInputTypeValues } from "@parachutehealth/canopy-text-input"
import { isSuccess } from "../api"

const updatePatientEmergencyContactMutationKey = [
  "updatePatientEmergencyContact",
]
const useUpdatePatientEmergencyContact = (
  fieldName: keyof PatientEmergencyContact
) => {
  const csrInboxState = useCsrInboxState()
  return useMutation({
    mutationFn: (fieldValue: string) =>
      updatePatientEmergencyContact(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        { [fieldName]: fieldValue }
      ),
    mutationKey: updatePatientEmergencyContactMutationKey,
  })
}

const NonCanopyConflictNamedTextField = ({
  patientEmergencyContact,
  label,
  fieldName,
  placeholder,
  type,
}: {
  patientEmergencyContact: PatientEmergencyContact
  fieldName: keyof PatientEmergencyContact
  label: string
  placeholder: string
  type?: CanopyTextInputTypeValues
}) => {
  const numberOfMutations = useIsMutating({
    mutationKey: updatePatientEmergencyContactMutationKey,
  })

  const [error, setError] = useState<string | undefined>(undefined)
  const [value, setValue] = useState(patientEmergencyContact[fieldName])
  const mutateEmergencyContact = useUpdatePatientEmergencyContact(fieldName)

  const handleError = (errorResponse) => {
    const errors = errorResponse.errors
    const error = errors[fieldName]
    setError(error ? error[0] : "Something went wrong")
  }

  const onBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (newValue !== value) {
      mutateEmergencyContact.mutateAsync(newValue).then((response) => {
        if (isSuccess(response)) {
          setError(undefined)
        } else {
          handleError(response)
        }
      })
      setValue(newValue)
    }
  }

  const val = patientEmergencyContact[fieldName]
  const formattedValue =
    fieldName === "phoneNumber" && val
      ? [val.slice(0, 3), val.slice(3, 6), val.slice(6)].join("-")
      : val

  return (
    <CanopyTextInputField
      label={label}
      defaultValue={formattedValue}
      onBlur={onBlur}
      feedbackMessage={error}
      placeholder={placeholder}
      readOnly={mutateEmergencyContact.isLoading || numberOfMutations > 0}
      type={type}
    />
  )
}

export const EmergencyContactInformation = ({
  patientEmergencyContact,
}: {
  patientEmergencyContact: PatientEmergencyContact
}) => {
  return (
    <>
      <h3 className="canopy-typography-heading-large">
        Emergency contact info
      </h3>
      <div className={styles.dataEntryLineContainer}>
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          label="First name"
          fieldName="firstName"
          placeholder="Enter first name"
        />
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          label="Last name"
          fieldName="lastName"
          placeholder="Enter last name"
        />
      </div>
      <div className={styles.dataEntryLineContainer}>
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          fieldName="phoneNumber"
          label="Phone number"
          placeholder="Enter phone number"
          type="tel"
        />
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          fieldName="relationship"
          label="Relationship"
          placeholder="Relationship to patient"
        />
      </div>
    </>
  )
}
