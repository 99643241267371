// @team @catalog-crew

import React from "react"

type CountContext = {
  count: Record<string, number>
  changeCount: (key: string, value: number) => void
}

export const CountContext = React.createContext<CountContext>({
  count: {},
  changeCount: () => {},
})

type CountState = Record<string, number>

/**
 * This CountProvider allows storing arbitrary numeric key/value pairs in a context where it can be
 * accessed by child components. It was written to store record counts to display in Tabs whose data
 * is loaded asynchronously, but it's generic enough to be used for anything that can relate
 * a single string key to a numeric value.
 * @example
 *  <CountProvider>
 *    <ParentComponent>
 *      const { count } = useContext(CountContext)
 *      <p>Count is {count["foo"]}</p>
 *
 *      <ChildComponent />
 *    </ParentComponent>
 *  </CountProvider>
 *
 *  <ChildComponent>
 *    const { changeCount } = React.useContext(CountContext)
 *    changeChange("foo", 100)
 *  </ChildComponent>
 */
export const CountProvider = ({ children }) => {
  const [count, setCount] = React.useState<CountState>({})

  const changeCount = (key: string, value: number) => {
    setCount((prev: Record<string, number>) => {
      return { ...prev, [key]: value }
    })
  }

  return (
    <CountContext.Provider value={{ count, changeCount }}>
      {children}
    </CountContext.Provider>
  )
}
