import { put } from "../../../../../services/api"
import { updatePatientEmergencyContactUrl } from "../../../urls"
import { PatientEmergencyContact } from "../../../sharedTypes"
import { ResourceUpdateResponse } from "../api"

export const updatePatientEmergencyContact = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  patientEmergencyContact: Partial<PatientEmergencyContact>
): Promise<ResourceUpdateResponse> => {
  return put(
    updatePatientEmergencyContactUrl(supplierOrganizationId, csrInboxStateId),
    {
      patientEmergencyContact,
    }
  )
    .then(() => ({ success: true }))
    .catch((e) => ({ errors: e.response?.data?.errors || {} }))
}
