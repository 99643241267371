import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "../../../../hooks/useServerSideDataGrid"
import {
  getPackageConfigurationsForSupplier,
  SupplierPackageConfigurationsDataGridResponse,
} from "./api"
import React, { useState } from "react"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { Supplier } from "../../../../types/sharedTypes"
import { isTest } from "../../../../../../utilities/environment"
import DataGridToolbar from "../../../../components/DataGridToolbar"
import InfoTooltip from "../../../../components/InfoTooltip"
import { CountContext } from "../../../../contexts/CountContext"

type PackageConfigurationsDataGridOptions = ServerSideDataGridOptions
type PackageConfigurationDataGridProps = {
  supplier: Supplier
}

const PackageConfigurationDataGrid: React.FC<PackageConfigurationDataGridProps> = ({
  supplier,
}: PackageConfigurationDataGridProps) => {
  const [gridData, setGridData] = React.useState<
    SupplierPackageConfigurationsDataGridResponse
  >({
    records: [],
    totalCount: 0,
  })

  const { changeCount } = React.useContext(CountContext)
  const [loading, setLoading] = useState(false)
  const beforeFetch = () => setLoading(true)
  const afterFetch = () => setLoading(false)

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "name",
        flex: 2,
        headerName: "Supplier Package Configuration",
        renderCell: (params) => {
          return (
            <>
              <a
                href={`/cms/catalog/packages/${params.row.packageExternalId}/suppliers/${supplier.externalId}/supplier_package_configurations/${params.row.externalId}`}
              >
                {params.value || "(no name)"}
              </a>
              {!params.row.fullyConfigured && (
                <InfoTooltip
                  title="This Supplier Package Configuration is not fully configured"
                  icon="triangle-exclamation"
                  className="color-danger canopy-mis-6x"
                />
              )}
            </>
          )
        },
      },
      {
        field: "externalId",
        headerName: "ID",
        flex: 0.6,
      },
      {
        field: "default",
        flex: 0.5,
        type: "boolean",
        headerName: "Default",
        renderCell: (params) => {
          return params.value ? "Yes" : "No"
        },
      },
      {
        field: "fullyConfigured",
        flex: 0.5,
        type: "boolean",
        headerName: "Configured",
        renderCell: (params) => {
          return params.value ? "Yes" : "No"
        },
      },
      {
        field: "consignmentClosetCount",
        flex: 0.5,
        type: "number",
        align: "left",
        headerAlign: "left",
        headerName: "Closets",
        renderCell: (params) => {
          return params.value || "-"
        },
      },
      {
        field: "packageName",
        flex: 2,
        headerName: "Package",
        renderCell: (params) => {
          return (
            <a href={`/cms/catalog/packages/${params.row.packageExternalId}`}>
              {params.value}
            </a>
          )
        },
      },
    ],
    [supplier.externalId]
  )

  const defaultOptions: PackageConfigurationsDataGridOptions = {
    page: 1,
    // todo: make this be driven by the backend? right now it's set in two different places
    sort: [{ field: "name", sort: "asc" }],
  }

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getPackageConfigurationsForSupplier(
      supplier.externalId,
      params
    )
    changeCount("packages", data.totalCount)
    setGridData((prev) => ({
      ...prev,
      records: data.records,
      totalCount: data.totalCount,
    }))
  }

  const {
    filterModel,
    options,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<PackageConfigurationsDataGridOptions>({
    trackHistory: false,
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  return (
    <div>
      <div className="canopy-mbe-12x d-flex align-items-end justify-content-space-between">
        <p className="canopy-mbe-0 canopy-typography-body-medium">
          Specific versions of global Packages offered by Suppliers.
        </p>
        {/* don't allow per-datagrid exports at this time */}
        {/*<CanopyButton
          iconStart="download"
          size="small"
          variant="secondary"
          as="a"
          href={`/cms/catalog/suppliers/${supplier.externalId}/packages.csv?${toRailsStyle(optionsToParams(options))}`}
        >
          Export
        </CanopyButton>*/}
      </div>
      <DataGridPro
        className="borderless"
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        loading={loading}
        columns={columns}
        rows={gridData.records}
        rowCount={gridData.totalCount}
        autoHeight
        getRowId={(row) => row.externalId}
        disableVirtualization={isTest()}
        hideFooterRowCount
        hideFooterSelectedRowCount
        filterMode="server"
        paginationMode="server"
        sortingMode="server"
        sortModel={options.sort}
        onSortModelChange={handleSortModelChange}
        components={{
          Toolbar: DataGridToolbar,
        }}
        componentsProps={{
          toolbar: { filter: true },
        }}
        pageSize={50}
        onPageChange={(page) => {
          handlePageChange(page + 1)
        }} // account for DataGrid's zero-based indexing
        //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[50]}
        pagination={true}
        density="compact"
      />
    </div>
  )
}

export default PackageConfigurationDataGrid
