import { Badge } from "@material-ui/core"
import React from "react"
import * as styles from "./index.module.scss"

type TabBadgeProps = {
  children: string | number
}

const TabBadge: React.FC<TabBadgeProps> = (
  props: TabBadgeProps
): JSX.Element => {
  const { children } = props
  return (
    <>
      <Badge
        max={9_999}
        badgeContent={children}
        className={styles.badge}
        variant="standard"
      />
    </>
  )
}

export default TabBadge
