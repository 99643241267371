import React from "react"
import { Route, Switch } from "react-router-dom"
import { pathMatch } from "./routes"
import * as styles from "./index.module.scss"
import {
  LLMResult,
  Patient,
  PatientEmergencyContact,
  Insurance,
} from "./sharedTypes"
import { Nullable } from "sharedTypes"
import LLMResultJson from "./components/LLMResultJson"
import { SupplierIntakeNavbar } from "./components/SupplierIntakeNavbar"
import { Document } from "components/DocumentsBrowser/sharedTypes"
import { withBrowserRouter } from "routers/BrowserRouter"
import { inboxDashboardUrl } from "./urls"
import { DataEntryColumn } from "./components/DataEntryColumn"
import {
  InboxStateClinicalFacility,
  setDoctorQueryData,
  setFacilityQueryData,
} from "./components/DataEntryColumn/FacilityData"
import { CsrInboxStateProvider } from "./csrInboxStateContext"
import { Diagnosis } from "./components/DataEntryColumn/Diagnoses"
import { PatientAddress } from "./components/DataEntryColumn/ContactInformation"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ErrorContextProvider } from "./error-context"
import { DocumentBrowserFrame } from "./components/DocumentBrowserFrame"

interface Props {
  insurances: Insurance[]
  patient: Patient
  patientAddress: PatientAddress
  patientEmergencyContact: Nullable<PatientEmergencyContact>
  diagnoses: Diagnosis[]
  llmResult: Nullable<LLMResult>
  livePilotEnabled: boolean
  supplier: { id: string }
  id: string
  supplierOrganization: { id: string }
  facility: InboxStateClinicalFacility
  doctor: { id?: string }
  document: Document
  documentTitle: string
}

const SupplierOrganizationInboxDocument = ({
  id,
  insurances,
  diagnoses,
  patient,
  patientAddress,
  patientEmergencyContact,
  livePilotEnabled,
  supplier,
  supplierOrganization,
  llmResult,
  document,
  documentTitle,
}: Props) => {
  return (
    <ErrorContextProvider>
      <CsrInboxStateProvider
        id={id}
        supplierOrganization={supplierOrganization}
        supplier={supplier}
        livePilotEnabled={livePilotEnabled}
      >
        <SupplierIntakeNavbar
          dashboardUrl={inboxDashboardUrl(supplierOrganization.id, supplier.id)}
          documentTitle={documentTitle}
        />
        <div className={styles.columnContainer}>
          <div className={styles.documentColumn}>
            <DocumentBrowserFrame document={document} />
          </div>
          <div className={styles.dataEntryColumn}>
            <Switch>
              <Route path={pathMatch("/json_blob")}>
                <LLMResultJson llmResult={llmResult} />
              </Route>
              <Route path={pathMatch()}>
                <DataEntryColumn
                  insurances={insurances}
                  diagnoses={diagnoses}
                  patient={patient}
                  patientAddress={patientAddress}
                  patientEmergencyContact={patientEmergencyContact || {}}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </CsrInboxStateProvider>
    </ErrorContextProvider>
  )
}

const SupplierOrganizationInboxDocumentWithQueryClientProvider = (
  props: Props
) => {
  const queryClient: QueryClient = new QueryClient()

  setFacilityQueryData(queryClient, props.facility)
  setDoctorQueryData(queryClient, props.doctor)
  return (
    <QueryClientProvider client={queryClient}>
      <SupplierOrganizationInboxDocument {...props} />
    </QueryClientProvider>
  )
}

export default withBrowserRouter(
  SupplierOrganizationInboxDocumentWithQueryClientProvider,
  {}
)
