import { DmeOrder } from "sharedTypes"

type Doctor = {
  pecosCertified: boolean
}

const dmeOrderRequiresPecos = (dmeOrder: DmeOrder): boolean => {
  return dmeOrderHasMedicareInsurance(dmeOrder)
}

export const canSign = (doctor: Doctor, dmeOrder: DmeOrder): boolean => {
  if (dmeOrderRequiresPecos(dmeOrder)) {
    return doctor.pecosCertified
  }
  return true
}

export const cannotSignForMedicareOrders = (
  doctor: Doctor,
  dmeOrder: DmeOrder
): boolean => {
  return dmeOrderRequiresPecos(dmeOrder) && !doctor.pecosCertified
}

export const medicareSigningAbilityUnknown = (
  doctor: Doctor,
  dmeOrder: DmeOrder
): boolean => {
  return dmeOrderHasManualInsurance(dmeOrder) && !doctor.pecosCertified
}

const dmeOrderHasMedicareInsurance = (dmeOrder: DmeOrder): boolean => {
  return !!dmeOrder.insurancePolicies.find(
    (insurancePolicy) =>
      insurancePolicy.followsMedicare && !insurancePolicy.manual
  )
}

const dmeOrderHasManualInsurance = (dmeOrder: DmeOrder): boolean => {
  return !!dmeOrder.insurancePolicies.find(
    (insurancePolicy) => insurancePolicy.manual
  )
}
