// @ts-strict-ignore
import React, { useState } from "react"
import DoctorSuggestion from "./Select/DoctorSuggestion"
import { DmeOrder } from "sharedTypes"
import { SuggestedDoctor } from "../sharedTypes"
import { newNpiDoctorRequestUrl } from "applications/Workflow/urls"
import { navigate } from "utilities/navigation"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { canSign } from "utilities/signature"
import { useQuery } from "@tanstack/react-query"
import { useDebounce } from "hooks/useDebounce"

type Props = {
  dmeOrder: DmeOrder
  doctorSearch({ value }): Promise<SuggestedDoctor[]>
  selectDoctor(id: string): Promise<void>
  suggestedDoctors: SuggestedDoctor[]
}

const doctorName = (doctor) => {
  return `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`
}

const getNewDoctorUrl = (name) => {
  const keywords = name.split(/\s+/)
  const keyword = keywords[0] || ""
  return `${newNpiDoctorRequestUrl()}?search_keyword=${keyword}`
}

const convertToOption = (doctor: SuggestedDoctor, dmeOrder: DmeOrder) => ({
  value: doctor.doctorId,
  label: doctorName(doctor),
  disabled: !canSign(doctor, dmeOrder),
  ...doctor,
})

const minSearchLength = 3

const ClinicianSelect: React.FC<Props> = ({
  dmeOrder,
  doctorSearch,
  selectDoctor,
  suggestedDoctors,
}) => {
  const [selectedDoctor, setSelectedDoctor] = useState(
    dmeOrder.doctor && convertToOption(dmeOrder.doctor, dmeOrder)
  )
  const [query, setQuery] = useState("")
  const debouncedSetQuery = useDebounce(setQuery, 200)

  const suggestedForYou = [
    {
      label: "Suggested for you",
      options: suggestedDoctors.map((doctor) =>
        convertToOption(doctor, dmeOrder)
      ),
    },
  ]

  const { data: options, isFetching } = useQuery({
    queryKey: ["clinicianSearch", dmeOrder.id, query],
    enabled: !!query && query.length >= minSearchLength,
    queryFn: async () => {
      const res = await doctorSearch({ value: query })
      return [
        ...res.map((doctor) => convertToOption(doctor, dmeOrder)),
        { isOther: true, value: query },
      ]
    },
  })

  const displayOptions = !query
    ? suggestedForYou
    : query.length < minSearchLength
    ? []
    : options || []

  const noOptionsFoundMessage =
    query && query.length < minSearchLength
      ? `Type ${minSearchLength} or more characters to search`
      : `No results for "${query}"`

  const onChange = (option: any) => {
    if (option?.isOther) {
      return navigate(getNewDoctorUrl(option.value))
    }
    selectDoctor(option?.doctorId)
    setSelectedDoctor(option)
  }

  return (
    <div className="wide-fixed-row">
      <CanopyComboboxField
        id="doctor"
        label="Who will be signing the order?"
        placeholder="Search by clinician name or NPI..."
        // @ts-expect-error options type is too prescriptive
        options={displayOptions}
        // @ts-expect-error value type is too prescriptive
        value={selectedDoctor}
        loading={isFetching}
        noOptionsFoundMessage={noOptionsFoundMessage}
        onInputChange={debouncedSetQuery}
        onChange={onChange}
        optionRenderFunction={({ option }: { option: any }) => {
          if (option.isOther) {
            return <a className="link">+ Create a New Clinician</a>
          }
          if (option.isTypeMore) {
            return (
              <span>Type {minSearchLength} or more characters to search</span>
            )
          }
          return (
            <DoctorSuggestion
              doctor={option}
              dmeOrder={dmeOrder}
              query={query}
            />
          )
        }}
      />
    </div>
  )
}

export default ClinicianSelect
