// @ts-strict-ignore
import React from "react"
import classNames from "classnames"
import { DmeOrder } from "sharedTypes"
import { SuggestedDoctor } from "../../sharedTypes"
import { highlight } from "utilities/select"
import { pluralize } from "utilities/string"
import {
  cannotSignForMedicareOrders,
  medicareSigningAbilityUnknown,
} from "utilities/signature"

type Props = {
  doctor: SuggestedDoctor & { disabled: boolean }
  dmeOrder: DmeOrder
  query?: string
}

function getWarningText(doctor: SuggestedDoctor, dmeOrder: DmeOrder): string {
  if (cannotSignForMedicareOrders(doctor, dmeOrder)) {
    return "Cannot sign for Medicare orders"
  }
  if (medicareSigningAbilityUnknown(doctor, dmeOrder)) {
    return "Medicare signing ability unknown"
  }
  return ""
}

const hasUniqueOtherName = (doctor: SuggestedDoctor): boolean =>
  (doctor.otherFirstName && doctor.firstName !== doctor.otherFirstName) ||
  (doctor.otherLastName && doctor.lastName !== doctor.otherLastName)

const DoctorSuggestion: React.FC<Props> = ({ doctor, dmeOrder, query }) => {
  const warning = getWarningText(doctor, dmeOrder)
  const address = [doctor.city, doctor.state].filter(Boolean).join(", ")
  return (
    <div key={doctor.doctorId} className="d-flex flex-column">
      <div className="d-flex">
        <div>
          <b>
            {highlight(
              `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`,
              query
            )}
          </b>
        </div>
        {address && (
          <div
            className={classNames("ml-auto", {
              "color-dark-gray": !doctor.disabled,
              "color-mid-gray": doctor.disabled,
            })}
          >
            {address}
          </div>
        )}
      </div>
      {doctor.clinicalOrganizations.length > 0 && (
        <small className="canopy-mt-1x">
          {doctor.clinicalOrganizations.join(", ")}
        </small>
      )}
      {hasUniqueOtherName(doctor) && (
        <div className="canopy-mt-1x">
          Other name: {doctor.otherFirstName} {doctor.otherLastName}
        </div>
      )}
      {!!doctor.signatureCount &&
        !cannotSignForMedicareOrders(doctor, dmeOrder) && (
          <small className="canopy-mt-1x">
            {doctor.signatureCount} signed{" "}
            {pluralize("order", doctor.signatureCount)}
          </small>
        )}
      {warning && <small className="canopy-mt-1x">{warning}</small>}
    </div>
  )
}

export default DoctorSuggestion
